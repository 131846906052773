.form {
    margin: 8px;
}

.formGroup {
    margin-bottom: 16px;
}

.highlight {
    font-size: 1.3rem;
    color: rgb(202, 18, 187);
}