.tileWrapper {
    background-color: #e9ecef;
    margin-bottom: 2rem;
}

.tileChildren {
    padding: 48px;
}

.tileValue {
    font-size: 2.5rem;
    margin-bottom: 8px;
}

.tileTitle {
    font-size: 1.75rem
}