.headerContainer {
	background: rgba(0,0,0,0.8);
	color: white;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
}

.title {
	padding: 20px;
	font-size: 20px;
}

.emptyDiv {
	height: 80px;
}