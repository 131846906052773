.buttonContainer {
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    margin: 0 4px;
    padding: 12px 0;
}

.buttonContainer.info {
    background-color: rgb(137, 137, 248);
}

.buttonContainer.warn {
    background-color: rgb(249, 249, 125);
    color: black;
}

.buttonContainer.danger {
    background-color: rgb(225, 86, 86);
}
